import React from "react";

function LogoFooter() {
    return (
        <div className="logo-footer-wrapper">
            <span>&copy;SR</span>
        </div>
    );
}
  
export default LogoFooter;