import React from "react";

function BodyWrapper({ children }) {
    return (
        <>
            {children}
        </>
    );
}
  
export default BodyWrapper;